/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:7dbd392c-60f6-46d3-8e6c-d4c2239042f6",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_JGxR4hOKT",
    "aws_user_pools_web_client_id": "1cad6mkmp0in3m3r84roemcqpo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tracklimit9de286af52ba40cebaa0cf9690869c9b173002-main",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
