<template>
  <section v-if="!awaitingAuthState" @keydown.esc="hideDialog">
    <template v-if="showHeader">
      <OrganismHeader></OrganismHeader>
    </template>

    <component :is="layout">
      <RouterView />
    </component>

    <template v-if="showFooter">
      <OrganismFooter />
    </template>

    <OrganismPanel v-if="panel" @close="hidePanel" @form-success="formSubmitted" />

    <Transition name="fade" appear>
      <OrganismDialog v-if="dialog" @close="hideDialog" />
    </Transition>

    <Transition name="fade" appear>
      <OrganismOverlay v-if="overlay" @close="hideOverlay" />
    </Transition>
  </section>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  const defaultLayout = 'Default'

  export default {
    computed: {
      layout() {
        return `${this.$route.meta.layout || defaultLayout}Layout`
      },
      showHeader() {
        return (
          (this.isLoggedIn && this.layout !== 'SidebarLayout') || (!this.isLoggedIn && this.layout !== 'SimpleLayout')
        )
      },
      showFooter() {
        return this.layout !== 'SidebarLayout' && this.layout !== 'LandingLayout'
      },
      isLoggedIn() {
        return !!this.loggedInUser
      },
      ...mapGetters({
        panel: 'getPanel',
        dialog: 'getDialog',
        overlay: 'getOverlay',
        loggedInUser: 'auth/getLoggedInUser',
        awaitingAuthState: 'auth/getAwaitingAuthState'
      })
    },
    async beforeCreate() {
      try {
        const user = await Auth.currentAuthenticatedUser()

        if (user) await this.$store.dispatch('auth/autoSignIn', user)
      } catch (e) {}

      this.$store.dispatch('auth/awaitingAuthCheck', false)
    },
    created() {
      this.setTodaysDate()
    },
    methods: {
      setTodaysDate() {
        const today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0')
        const yyyy = today.getFullYear()

        this.$store.dispatch('setToday', `${yyyy}-${mm}-${dd}`)
      },
      hideDialog() {
        this.$store.dispatch('setDialog', null)
      },
      hideOverlay() {
        this.$store.dispatch('setOverlay', null)
      },
      hidePanel() {
        this.$store.dispatch('setPanel', null)
      },
      formSubmitted(values) {
        this.hidePanel()
        this.$store.dispatch('setPanelStatus', values)
      }
    }
  }
</script>
